const jwt = require("jsonwebtoken");
const { http } = require("../services/config");
import Cookie from "js-cookie";

export default {
  async DataUser() {
    const VITE_SECRET = process.env.VITE_SECRET;

    const token = Cookie.get("token");

    if (token) {
      try {
        const unidadeLocalStorage = localStorage.getItem("unidade");
        const { sub } = await jwt.verify(token, VITE_SECRET);
        const id = sub;
        return await http
          .get(`users/${id}?unidade=${unidadeLocalStorage}`)
          .then((res) => {
            return res;
          })
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    }
  },
};
